<template>
  <div>
    <v-text-field
      label="Сотрудник"
      :value="selectedEmp[0]?selectedEmp[0].EmpFIO||selectedEmp[0].empfio:'Выбрать сотрудника'"
      readonly
      prepend-inner-icon="mdi-account-search-outline"
      :disabled="disablebtn"
      @click="showDialog"
    />
    <!-- ОКНО СОТРУДНИКИ (START) -->
    <v-dialog
      v-model="show"
      max-width="800"
    >
      <v-card>
        <v-card-title>
          <v-spacer />
          <v-icon
            aria-label="Close"
            @click="cancelEmp"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <base-material-card
          color="warning"
          inline
          class="px-5 py-3"
        >
          <template #heading>
            <div class="text-h3 font-weight-light">
              <v-icon>mdi-account-supervisor-outline</v-icon> Cотрудники
            </div>
          </template>
          <v-text-field
            v-model="searchEmps"
            append-icon="mdi-account-search-outline"
            class="mb-5"
            label="Поиск.."
            single-line
            hide-details
          />
          <v-data-table
            v-model="selectedEmp"
            :headers="headersEmp"
            :items="empList"
            :sort-by="['shortfio']"
            :items-per-page="5"
            class="elevation-1 mb-5 emps"
            item-key="empid"
            single-select
            show-select
            :loading="tableEmpLoading"
            :search="searchEmps"
            @click:row="selectEmp"
          />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="cancelEmp"
            >
              Отмена
            </v-btn>
            <v-btn
              class="v-btn--right"
              color="success"
              :disabled="!selectedEmp"
              @click="submitEmp"
            >
              Выбрать
            </v-btn>
          </v-card-actions>
        </base-material-card>
      </v-card>
    </v-dialog>
    <!-- ОКНО СОТРУДНИКИ (END) -->
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'EmpSelect',
    props: {
      customClass: {
        type: String,
        default: '',
      },
      disablebtn: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: '',
      },
      empList: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        show: false,
        searchEmps: '',
        tableLoading: false,
        tableEmpLoading: false,
      }
    },
    computed: {
      ...mapGetters({
        empid: 'empid',
        isAuthenticated: 'isAuthenticated',
      }),
      selectedEmp: {
        get: function () {
          return this.$store.getters.selectedEmp
        },
        set: function (newValue) {
          this.$store.dispatch('setSelectedEmp', newValue)
        },
      },
      headersEmp () {
        return [
          {
            text: 'Номер',
            align: 'center',
            value: 'empid',
            width: '30%',
          },
          {
            text: 'ФИО',
            align: 'left',
            sortable: true,
            value: 'shortfio',
          },
          {
            text: 'Отдел',
            value: 'departmenttitle',
            filterable: false,
            sortable: false,
          },
          {
            text: 'Должность',
            value: 'posttitle',
            filterable: false,
            sortable: false,
          },
        ]
      },
    },
    methods: {
      empListComp (list) {
        list.forEach((x) => {
          const keys = Object.keys(x)
          let n = keys.length
          while (n--) {
            const key = keys[n] // "cache" it, for less lookups to the array
            if (key !== key.toLowerCase()) { // might already be in its lower case version
              x[key.toLowerCase()] = x[key] // swap the value to a new lower case key
              delete x[key] // delete the old key
            }
          }
        })
      },
      showDialog () {
        this.show = true
      },
      selectEmp (selectedItem) {
        this.tableLoading = true
        this.selectedEmp = []
        this.selectedEmp.push(selectedItem)
        this.tableLoading = false
      },
      cancelEmp () {
        this.selectedEmp = []
        this.show = false
      },
      submitEmp () {
        this.show = false
      },

    },
  }
</script>
